const initialState = {
  showRoomsLoading: false,
  showroomsData: null,
  showRoomItem: null,
  error: null,
};

export const showRoomsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SHOWROOMS":
      return { ...state, showroomsData: action.payload, showRoomsLoading: false };
    case "FETCH_SHOWROOM_ITEM":
      return { ...state, showRoomItem: action.payload, showRoomsLoading: false };
    case "SHOWROOM_LOADING":
      return { ...state, showRoomsLoading: true };
    default:
      return state;
  }
};
