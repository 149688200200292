import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateShowroom } from "../../REDUX/actions/revendeur";
import CustomButton from "../Common/CustomButton";
import InputText from "../sectioninput/formelement/InputText";
import _ from "lodash";
const ShowRoomForm = ({ marker, showRoomItem }) => {
  const dispatch = useDispatch();

  const [showRoomsInputs, setshowRoomsInputs] = useState(
    _.omit(showRoomItem.attributes, [
      "locale",
      "createdAt",
      "updatedAt",
      "seo",
      "banner",
      "list",
      "adress",
      "photos_magasin",
      "products",
      "events",
    ])
  );
  const [adressData, setadressData] = useState(
    _.omit(showRoomItem.attributes.adress.data.attributes, [
      "locale",
      "createdAt",
      "updatedAt",
      "seo",
      "event",
      "revendeur",
      "logement",
    ])
  );
  useEffect(() => {
    setshowRoomsInputs(
      _.omit(showRoomItem.attributes, [
        "locale",
        "createdAt",
        "updatedAt",
        "seo",
        "banner",
        "list",
        "adress",
        "photos_magasin",
        "products",
        "events",
      ])
    );
    setadressData(
      _.omit(showRoomItem.attributes.adress.data.attributes, [
        "locale",
        "createdAt",
        "updatedAt",
        "seo",
        "event",
        "logement",
        "revendeur",
        "partenaire",
      ])
    );
  }, [showRoomItem]);

  const handleShowroomChange = (value, champs) => {
    setshowRoomsInputs({ ...showRoomsInputs, [champs]: value });
  };

  const handleAdressChange = (value, champs) => {
    setadressData({ ...adressData, [champs]: value });
  };

  const handleUpdateAdress = () => {
    dispatch(
      updateShowroom(
        showRoomItem.id,
        showRoomsInputs,
        showRoomItem.attributes.adress.data.id,
        {
          ...adressData,
          coordinates: [marker.getLngLat().lng, marker.getLngLat().lat],
        }
      )
    );
  };
  return (
    <>
      <div className="mt-2">
        <InputText
          labelinput="nom"
          handleChange={handleShowroomChange}
          champs="name"
          value={showRoomsInputs.name}
        />
        <InputText
          labelinput="Descrption"
          textArea
          handleChange={handleShowroomChange}
          champs="description"
          value={showRoomsInputs.description}
        />

        <InputText
          labelinput="Site Web"
          handleChange={handleShowroomChange}
          champs="website"
          value={showRoomsInputs.website}
        />
        <InputText
          labelinput="Téléphone"
          handleChange={handleShowroomChange}
          champs="phoneNumber"
          value={showRoomsInputs.phoneNumber}
        />
        <InputText
          labelinput="E-mail"
          handleChange={handleShowroomChange}
          champs="email"
          value={showRoomsInputs.email}
        />
        <InputText
          labelinput="Jours d'ouverture"
          handleChange={handleShowroomChange}
          champs="openDates"
          value={showRoomsInputs.openDates}
        />
        <InputText
          labelinput="Horaire d'ouverture"
          handleChange={handleShowroomChange}
          champs="openTime"
          value={showRoomsInputs.openTime}
        />
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <InputText
            labelinput="Adresse compléte"
            handleChange={handleAdressChange}
            champs="fullAdress"
            value={adressData.fullAdress}
          />
          <InputText
            labelinput="Code Postal"
            handleChange={handleAdressChange}
            champs="postalCode"
            value={adressData.postalCode}
          />
          <InputText
            labelinput="Ville"
            handleChange={handleAdressChange}
            champs="city"
            value={adressData.city}
          />
          <InputText
            labelinput="Pays"
            handleChange={handleAdressChange}
            champs="country"
            value={adressData.country}
          />
        </div>
      </div>
      <CustomButton
        buttonText={"Modifier"}
        iconStart={"pencil-fill"}
        callBack={handleUpdateAdress}
        disabled={
          !showRoomsInputs.name ||
          !showRoomsInputs.description ||
          !adressData.fullAdress
        }
      />
    </>
  );
};

export default ShowRoomForm;
