import React, { useState } from "react";
import { Form } from "react-bootstrap";

const Switch = ({ values = [1, 2, 3, 4], label, required = false, handleChange, champs, value, canBeDisabled = false }) => {
  const [show, setshow] = useState(required);

  const handleShow = (e) => {
    setshow(e.target.checked);
    if (!e.target.checked) {
      handleChange(0, champs);
    } else {
      handleChange(2, champs);
    }
  };

  return (
    <div className="d-flex mt-4">
      <p className="me-2">{label}</p>
      <Form.Check className="me-2" type="switch" defaultChecked={required} onChange={(e) => handleShow(e)} disabled={required && !canBeDisabled} />
      {show && (
        <div>
          {values.map((itemValue, index) => (
            <Form.Check
              key={index}
              inline
              label={itemValue.text}
              value={itemValue.value}
              // defaultValue="2"
              defaultChecked={itemValue.value === value}
              name={`checkbox${label}`}
              type="radio"
              onChange={(e) => {
                handleChange(itemValue.value, champs);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Switch;
