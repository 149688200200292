const initialState = {
  contactsLoading: false,
  contacts: null,
  contactItem: null,
  newContact: false,
  pages: 1,
};

export const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONTACTS":
      const pages = Math.ceil(action.payload.meta.pagination.total / 10);
      return { ...state, contacts: action.payload.data, pages: pages, contactsLoading: false };
    case "FETCH_CONTACT_ITEM":
      return { ...state, contactItem: action.payload, contactsLoading: false };
    case "ITEM_DELETED":
      return { ...state, contacts: state.contacts.filter((el) => el.id !== action.payload), contactsLoading: false };
    case "CONTACT_LOADING":
      return { ...state, contactsLoading: true };
    case "CHECK_NEW_CONTACT":
      return { ...state, newContact: action.payload, contactsLoading: false };
    default:
      return state;
  }
};
