const initialState = {
  filesLoading: false,
  refetching: false,
  files: null,
  error: null,
  dossiers: [],
};

export const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FILES":
      return { ...state, files: action.payload, filesLoading: false };
    case "REFETCH_FILES":
      return { ...state, files: [...state.files, ...action.payload], filesLoading: false, refetching: false };
    case "REFETCHING":
      return { ...state, refetching: true };
    case "FILES_LOADING":
      return { ...state, filesLoading: true };
    case "RENAME_FILE":
      return { ...state, filesLoading: false, files: state.files.map((el) => (el.id === action.payload.id ? { ...el, attributes: { ...el.attributes, name: action.payload.name } } : el)) };

    case "FETCH_DOCUMENTS":
      action.payload.forEach((dossier) => {
        let fichierIds = [];
        dossier.attributes.fichiers.data.forEach((fichier) => {
          fichierIds = [...fichierIds, fichier.id];
        });
        dossier.fichiersId = fichierIds;
      });
      return { ...state, dossiers: action.payload };
    default:
      return state;
  }
};
