import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../REDUX/actions/users";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import InputText from "../sectioninput/formelement/InputText";
import UserCard from "./UserCard";

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
    // eslint-disable-next-line
  }, []);

  const goToNewUser = () => navigate(`/newuser`);

  const { userLoading, allUsers } = useSelector((state) => state.usersReducer);
  return userLoading || !allUsers.length ? (
    <Loading />
  ) : (
    <>
      <div className="row f24 align-items-center">Liste des utilisateurs</div>
      <div className="row align-items-center mt-5">
        <div className="col-9">
          <InputText flag={false} placeholder="rechercher un utilisateur" />
        </div>
        <div className="col-3">
          <CustomButton buttonText="Ajouter un utilisateur" iconStart={"plus-lg"} callBack={goToNewUser} />
        </div>
      </div>
      <div className="row mb-4 w-98 m-auto mt-5">
        <div className="col-3 f16 bolder text-start">Nom Complet </div>

        <div className="col-3 f16 bolder text-start">E-mail</div>
        <div className="col-2 f16 bolder text-start">Role</div>
        <div className="col-4 f16 bolder text-start">Action</div>
      </div>
      <div>
        {allUsers.map((user, index) => (
          <UserCard user={user} key={index} index={index} />
        ))}
      </div>
    </>
  );
};

export default UserList;
