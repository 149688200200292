import React from "react";
import "./CustomButton.css";

const CustomButton = ({ buttonText, iconStart, iconEnd, outlined = false, height = 35, type = "primary", callBack, disabled = false }) => {
  return (
    <button disabled={disabled} onClick={callBack} style={{ height: `${height}px` }} className={`myBtn ${outlined ? "customButtonOutlined" : "customButton"}${type} `}>
      {iconStart && <i className={`bi bi-${iconStart} ${buttonText ? "me-3" : ""} iconCustomBtn`}></i>}
      {buttonText}
      {iconEnd && <i className={`bi bi-${iconEnd} ms-3 iconCustomBtn`}></i>}
    </button>
  );
};

export default CustomButton;
