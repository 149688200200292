import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import { getAllPartenaires } from "../../REDUX/actions/partenaire";
import AddNewPartenaire from "../../Components/Partenaires/AddNewPartenaire";
import PartenaireList from "../../Components/Partenaires/PartenaireList";

const PartenaireView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPartenaires());
    // eslint-disable-next-line
  }, []);
  const { partenairesData, partenairesLoading } = useSelector(
    (state) => state.partenairesReducer
  );

  return partenairesLoading || !partenairesData ? (
    <Loading />
  ) : (
    <>
      {/* <PageCard page={ActivePage} collapsed /> */}
      <AddNewPartenaire />
      <PartenaireList />
    </>
  );
};

export default PartenaireView;
