import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../../REDUX/actions/products";
import { sitePages } from "../../../sitePages";

const CustomSelect = ({ handleChange, value }) => {
  const dispatch = useDispatch();
  const [typeSelected, settypeSelected] = useState(0);
  const { productsIdAndNames } = useSelector((state) => state.productsReducer);

  useEffect(() => {
    if (!productsIdAndNames) {
      dispatch(getAllProducts());
    }
    // eslint-disable-next-line
  }, []);

  const buttonTypes = [
    {
      text: "Page du Site",
      value: 0,
    },
    {
      text: "Vers un produit",
      value: 1,
    },
    {
      text: "Lien Externe",
      value: 2,
    },
  ];

  const checkValue = (valeur) => {
    if (valeur) {
      if (valeur[0] === "/") {
        return { type: 0, tab: sitePages };
      } else if (valeur.includes("product")) {
        return { type: 1, tab: productsIdAndNames };
      } else {
        return 2;
      }
    } else return {};
  };
  const [groupSelected, setgroupSelected] = useState(
    checkValue(value).tab || []
  );

  const handleChangeType = (value) => {
    settypeSelected(value);
    if (value === 0) {
      setgroupSelected(sitePages);
    } else if (value === 1) {
      setgroupSelected(productsIdAndNames);
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <label className="f16 ">Type du bouton </label>
        <Form.Select
          onChange={(e) => handleChangeType(parseInt(e.target.value))}
          defaultValue={checkValue(value).type}
        >
          {buttonTypes.map((el, index) => (
            <option key={index} value={el.value}>
              {el.text}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="col-6">
        <label className="f16 ">Déstination du bouton </label>
        {typeSelected !== 2 ? (
          <Form.Select
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={value}
          >
            {groupSelected.map((el, index) => (
              <option key={index} value={el.value}>
                {el.text}
              </option>
            ))}
          </Form.Select>
        ) : (
          <input
            type="text"
            className="styleInput w-100 ps-3 rounded-3 "
            defaultValue={value}
            onChange={(e) => handleChange(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
