import React from "react";
import "./Loadings.css";

const LoadingHeader = () => {
  return (
    <div className="headerLoadingContainer">
      <div className="LoadingBar"></div>
    </div>
  );
};

export default LoadingHeader;
