export const openModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_MODAL",
    payload: data,
  });
};

export const closeModal = (data) => async (dispatch) => {
  dispatch({
    type: "CLOSE_MODAL",
  });
};
