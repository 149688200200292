import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, getAllArticles } from "../../REDUX/actions/articles";
import CustomButton from "../Common/CustomButton";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import InputText from "../sectioninput/formelement/InputText";
import ArticleCard from "./ArticleCard";

const ArticlesList = () => {
  const dispatch = useDispatch();
  const [filter, setfilter] = useState("");

  const { articles, articlesLoading } = useSelector(
    (state) => state.articlesReducer
  );

  const createArticle = () => dispatch(addArticle());
  const filterArticles = () => {
    dispatch(getAllArticles(filter));
  };
  return (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des articles</div>
      <div className="row align-items-center mt-2">
        <div className="col-9 d-flex align-items-center">
          <InputText
            flag={false}
            placeholder="rechercher un article"
            handleChange={setfilter}
          />
          <div className="ms-3 col-1">
            <CustomButton
              iconStart={"search"}
              callBack={() => filterArticles(filter)}
            />
          </div>
        </div>
        <div className="col-3">
          <CustomButton
            buttonText={"Ajouter un article"}
            iconStart={"plus-lg"}
            callBack={createArticle}
          />
        </div>
      </div>
      <div className="row mb-4 w-98 m-auto mt-5">
        <div className="col-4 f16 bolder text-start">Titre</div>
        <div className="col-2 f16 bolder text-start">Publié</div>
        <div className="col-6 f16 bolder text-start">Actions</div>
      </div>
      {articlesLoading ? (
        <Loading />
      ) : (
        <div className="mt-5">
          {articles.length ? (
            articles.map((article, index) => (
              <ArticleCard key={index} article={article} index={index} />
            ))
          ) : (
            <EmptyList text="Pas d'articles" />
          )}
        </div>
      )}{" "}
    </div>
  );
};

export default ArticlesList;
