import API from "../../Api/Api.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllArticles = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: {
        title: {
          $containsi: filter,
        },
      },
      populate: {
        banner: {
          populate: "*",
        },
      },
      sort: ["createdAt:desc"],
    },

    {
      encodeValuesOnly: true,
    }
  );
  dispatch({ type: "ARTICLE_LOADING" });
  try {
    let { data } = await API.get("api/articles?" + query);
    dispatch({ type: "FETCH_ARTICLES", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const deletArticle = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};
  dispatch({ type: "ARTICLE_LOADING" });
  try {
    await API.delete(`api/articles/${id}`);
    dispatch(getAllArticles());
    dispatch(closeModal());
    dispatch(handleInfo("Article supprimé", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression de l'article", 1));
    console.log(error);
  }
};

export const publishOrNoPublishArticle =
  (id, published) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    dispatch({ type: "ARTICLE_LOADING" });
    try {
      await API.put(`api/articles/${id}`, { data: { publish: published } });
      dispatch(getArticleItem(id));
      dispatch(getAllArticles());
      dispatch(closeModal());
      dispatch(handleInfo(published ? "Article publié" : "Article retiré", 1));
    } catch (error) {
      dispatch(handleError("Erreur lors de la publication de l'article", 1));
      console.log(error);
    }
  };

export const addArticle = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "ARTICLE_LOADING" });
  try {
    let image = await API.get("api/upload/files");
    defaultImage = image.data[0].id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moin une image pour procéder", 1)
      );
    }
    const defaultBanner = {
      title: "banniere par default",
      subtitle: "soustitre par default",
      image: [defaultImage],
      imageMobile: [defaultImage],
    };

    let defaultArticleFR = {
      title: "titre article par défault",
      subtitle: "sous-titre article par défault",
      author: "auteur par défault",
      authorImage: defaultImage,
      description: "description Par défault",
    };

    let defaultSEOFR = {
      title: "titre SEO article par defaut",
      description: "SEO description article par defaut",
      ogimage: defaultImage,
    };

    let SEOfr = await API.post("/api/seos", {
      data: defaultSEOFR,
    });

    let bannerfr = await API.post("/api/sections", {
      data: defaultBanner,
    });

    await API.post("/api/articles", {
      data: {
        banner: bannerfr.data.data.id,
        seo: SEOfr.data.data.id,
        ...defaultArticleFR,
      },
    });

    dispatch(handleInfo("Article Créé", 1));
    dispatch(getAllArticles());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const getArticleItem = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: { id: { $eq: id } },
      populate: {
        banner: {
          populate: "*",
        },
        sections: {
          populate: "*",
        },
        authorImage: {
          populate: "*",
        },
        seo: {
          populate: "ogimage",
        },
      },
    },
    { encodeValuesOnly: true }
  );
  dispatch({ type: "ARTICLE_LOADING" });
  try {
    let { data } = await API.get("/api/articles?" + query);

    dispatch({ type: "FETCH_ARTICLE_ITEM", payload: data.data[0] });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updateArticle = (id, article) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "ARTICLE_LOADING" });
  try {
    await API.put(`/api/articles/${id}`, { data: article });

    dispatch(handleInfo("Article Modifié", 1));
    dispatch(getArticleItem(id));
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};
