import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeassociatedShowrooms } from "../../REDUX/actions/events";
import { getAllShowrooms } from "../../REDUX/actions/revendeur";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import LoadingButton from "../Common/LoadingButton";

const EventsAssociatedShowrooms = ({ eventItem }) => {
  const dispatch = useDispatch();
  const { showroomsData, showRoomsLoading } = useSelector(
    (state) => state.showRoomsReducer
  );
  const { eventsLoading } = useSelector((state) => state.eventsReducer);
  useEffect(() => {
    if (!showroomsData) {
      dispatch(getAllShowrooms());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllShowrooms());
    // eslint-disable-next-line
  }, []);

  const [associatedShowrooms, setassociatedShowrooms] = useState();

  useEffect(() => {
    if (eventItem) {
      setassociatedShowrooms(
        eventItem.attributes.revendeurs.data.map((el) => el.id)
      );
    }
  }, [eventItem]);

  const handleExposedModels = () => {
    dispatch(changeassociatedShowrooms(eventItem.id, associatedShowrooms));
  };
  return !showroomsData || !associatedShowrooms || showRoomsLoading ? (
    <Loading />
  ) : (
    <Accordion className={`text-start rounded-10 `}>
      <Accordion.Item
        className="card-body noBorder p-0 rounded-10"
        eventKey="0"
      >
        <Accordion.Header className="rounded-10">
          <h2 className="mb-0 f24 fw-normal">Les revendeurs associés</h2>
        </Accordion.Header>
        <Accordion.Body>
          <form className="d-flex flex-wrap justify-content-center">
            {showroomsData.map((el, index) => (
              <div className="form-check form-switch my-3 w-20" key={index}>
                <input
                  defaultChecked={associatedShowrooms.includes(el.id)}
                  className="form-check-input"
                  value={el.id}
                  type="checkbox"
                  id={el.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      associatedShowrooms.push(parseInt(e.target.value));
                    } else {
                      const i = associatedShowrooms.indexOf(
                        parseInt(e.target.value)
                      );
                      if (i > -1) {
                        associatedShowrooms.splice(i, 1);
                      }
                    }
                  }}
                />
                <label className="form-check-label" htmlFor={el.id}>
                  {el.attributes.name}
                </label>
              </div>
            ))}
          </form>
          <div className="my-3">
            <CustomButton
              disabled={eventsLoading}
              buttonText={
                eventsLoading ? (
                  <LoadingButton />
                ) : (
                  "Enregistrer les revendeurs associés"
                )
              }
              callBack={handleExposedModels}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EventsAssociatedShowrooms;
