const initialState = {
  welcome: false,
  welcomeMessage: null,
  info: false,
  infoMessage: null,
  error: false,
  errorMessage: null,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_WELCOME":
      return { ...state, welcome: true, welcomeMessage: action.payload };
    case "HIDE_WELCOME":
      return { ...state, welcome: false, welcomeMessage: action.payload };
    case "SHOW_INFO":
      return { ...state, info: true, infoMessage: action.payload };
    case "HIDE_INFO":
      return { ...state, info: false, infoMessage: action.payload };
    case "SHOW_ERROR":
      return { ...state, error: true, errorMessage: action.payload };
    case "HIDE_ERROR":
      return { ...state, error: false, errorMessage: action.payload };
    case "CLOSE_NOTIFICATION":
      return { ...state, error: false, errorMessage: null, welcome: false, welcomeMessage: null, info: false, infoMessage: null };
    default:
      return state;
  }
};
