//LES ARTS GRAPHIQUES

export const END_POINT = "https://novanea-api-media.labo-linkibe.fr";
export const FRONT_SITE_URL = "https://lesartsgraphiques.fr/";
export const ProjectName = "NOVANEA";

export const drawerOnglets = [
  {
    name: "Dashboard",
    icon: "bi-columns-gap",
    to: "/",
    showOnlyToAdmin: false,
    view: "dashboard",
  },

  // {
  //   name: "Blog",
  //   icon: "bi-file-earmark-fill",
  //   to: "/blog",
  //   showOnlyToAdmin: false,
  //   view: "blog",
  // },
  // {
  // name: "Réalisation",
  //  icon: "bi-calendar-event",
  //  to: "/realisation",
  //  showOnlyToAdmin: false,
  //  view: "events",
  // },
  {
    name: "Pages",
    icon: "bi-file-earmark-code",
    to: "/pages",
    showOnlyToAdmin: false,
    view: "pages",
  },

  // {
  //   name: "Produits",
  //   icon: "bi-shop",
  //   to: "/products",
  //   showOnlyToAdmin: false,
  //   view: "produits",
  // },

  {
    name: "Nos Agences",
    icon: "bi-pin-map",
    to: "/nos-agences",
    showOnlyToAdmin: false,
    view: "revendeurs",
  },
  {
    name: "Nos Partenaires",
    icon: "bi-pin-map",
    to: "/nos-partenaires",
    showOnlyToAdmin: false,
    view: "partenaires",
  },

  {
    name: "Contacts",
    icon: "bi-envelope-fill",
    to: "/contact",
    showOnlyToAdmin: false,
    view: "contacts",
  },
  {
    name: "Fichiers",
    icon: "bi-card-image",
    to: "/files",
    showOnlyToAdmin: false,
    view: "fichiers",
  },
  {
    name: "Utilisateurs",
    icon: "bi-people-fill",
    to: "/users",
    showOnlyToAdmin: true,
    view: "users",
  },
  {
    name: "Mon Compte",
    icon: "bi-person-circle",
    to: "/account",
    showOnlyToAdmin: false,
    view: "account",
  },

  //NOT SHOW IN DRAWER
  // {
  //   name: "Produits element",
  //   hideInDrawer: true,
  //   to: "/products/:id",
  //   showOnlyToAdmin: false,
  //   view: "productItem",
  // },

  // {
  //   name: "Articles element",
  //   hideInDrawer: true,
  //   to: "/blog/:id",
  //   showOnlyToAdmin: false,
  //   view: "articleItem",
  // },

  // {
  //   name: "Events element",
  //   hideInDrawer: true,
  //   to: "/realisation/:id",
  //   showOnlyToAdmin: false,
  //   view: "eventItem",
  // },

  {
    name: "agence element",
    hideInDrawer: true,
    to: "/agence/:id",
    showOnlyToAdmin: false,
    view: "showRoomItem",
  },
  {
    name: "partenaire element",
    hideInDrawer: true,
    to: "/partenaire/:id",
    showOnlyToAdmin: false,
    view: "partenaireItem",
  },
  {
    name: "Pages element",
    hideInDrawer: true,
    to: "/pages/:id",
    showOnlyToAdmin: false,
    view: "pageItem",
  },
  {
    name: "Contact element",
    hideInDrawer: true,
    to: "/contact/:id",
    showOnlyToAdmin: false,
    view: "contactItem",
  },
  {
    name: "Nouvel utilisateur",
    hideInDrawer: true,
    to: "/newUser",
    showOnlyToAdmin: true,
    view: "newUser",
  },
];

export const pageNames = {
  // blog: "Actualite",
  // events: "Realisation",
  home: "Accueil",
  // products: "Nos réalisations",
  // magasins: "Page Revendeur",
};

export const redirection = {
  eventRoute: "realisation",
  blogRoute: "blog",
};
