import API from "../../Api/Api.js";
import { handleError } from "./notifications.js";
import qs from "qs";
import { closeModal } from "./confirmationModal.js";
import axios from "axios";
const token = localStorage.getItem("token");

export const getAllContacts = (pageNumber) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      sort: ["createdAt:desc"],
      pagination: {
        page: pageNumber,
        pageSize: 10,
      },
    },

    {
      encodeValuesOnly: true,
    }
  );

  dispatch({ type: "CONTACT_LOADING" });
  try {
    let { data } = await API.get(`/api/contacts?${query}`);
    dispatch({ type: "FETCH_CONTACTS", payload: data });
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};

export const getContactItem = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "CONTACT_LOADING" });
  try {
    let { data } = await API.get(`/api/contacts/${id}`);
    dispatch({ type: "FETCH_CONTACT_ITEM", payload: data.data });
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};

export const deleteContact = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "CONTACT_LOADING" });
  try {
    await API.delete(`/api/contacts/${id}`);
    dispatch({ type: "ITEM_DELETED", payload: id });
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));
    dispatch(closeModal());

    console.log(error);
  }
};

export const setContactViewed = (id, viewedBy) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "CONTACT_LOADING" });
  try {
    await API.put(`/api/contacts/${id}`, { data: { viewedBy: viewedBy } });
    dispatch(getContactItem(id));
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};

export const checkNewContact = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        viewedBy: {
          $eq: "Vue Par : ",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );

  dispatch({ type: "CONTACT_LOADING" });
  try {
    let { data } = await API.get(`/api/contacts?${query}`);
    if (data.data.length) {
      dispatch({ type: "CHECK_NEW_CONTACT", payload: true });
    } else {
      dispatch({ type: "CHECK_NEW_CONTACT", payload: false });
    }
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};

export const sendEmail = (id, viewedBy) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  // dispatch({ type: "CONTACT_LOADING" });
  try {
    await axios.post(`http://localhost:1337/api/contacts/`, {
      data: { firstName: "viewedBy" },
    });
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};
