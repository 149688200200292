import API from "../../Api/Api.js";
import { productUrlRewrite } from "../../utils/utils.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllProducts = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  // const query = qs.stringify(
  //   {
  //     filters: {
  //       name: {
  //         $containsi: filter,
  //       },
  //     },
  //     populate: {
  //       image: {
  //         populate: {
  //           url: "*",
  //         },
  //       },
  //       image2: {
  //         populate: {
  //           url: "*",
  //         },
  //       },
  //     },
  //     sort: ["createdAt:desc"],
  //   },

  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  // dispatch({ type: "PRODUCTS_LOADING" });
  // try {
  //   let { data } = await API.get("api/products?" + query);
  //   dispatch({ type: "FETCH_PRODUCTS", payload: data.data });
  // } catch (error) {
  //   dispatch(handleError("erreur réseau", 1));
  //   console.log(error);
  // }
};

export const deleteProduct = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    await API.delete(`api/products/${id}`);
    dispatch(getAllProducts());
    dispatch(handleInfo("Produit supprimé", 1));
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression du produit", 1));
    console.log(error);
  }
};

export const getProductItem = (id, noLoading) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: { id: { $eq: id } },
      populate: {
        banner: {
          populate: "*",
        },
        image: {
          populate: {
            url: "*",
          },
        },
        image2: {
          populate: {
            url: "*",
          },
        },
        ficheTechnique: {
          populate: {
            url: "*",
          },
        },
        pointfort: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        realisation: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        pointreassurance: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        versionImages: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        jetList: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        relatedProducts: {
          populate: "attributes",
        },
        colors: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        cuveColors: {
          populate: {
            cards: { populate: ["image", "image2"], sort: ["order:asc"] },
          },
        },
        seo: {
          populate: "ogimage",
        },
      },
    },
    { encodeValuesOnly: true }
  );
  if (!noLoading) {
    dispatch({ type: "PRODUCTS_LOADING" });
  }

  try {
    let { data } = await API.get("/api/products?" + query);

    dispatch({ type: "FETCH_PRODUCT_ITEM", payload: data.data[0] });
    dispatch({ type: "LIST_DONE_LOADING" });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

const createList = async (defaultObject, name, resolution) => {
  let cardFR = await API.post("/api/cards", {
    data: defaultObject,
  });

  let defaultListFR = {
    name: `Liste ${name} produit`,
    resolution: resolution,

    cards: [cardFR.data.data.id],
  };

  let listFR = await API.post("/api/lists", {
    data: defaultListFR,
  });

  return { listFR };
};

export const addProduct = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    let image = await API.get("api/upload/files");
    defaultImage = image.data[0].id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moin une image pour procéder", 1)
      );
    }
    let pointfort = {
      name: `carte point fort produit par défault`,
      title: `Titre point fort par default`,
      description: `Description point fort par default`,
      content: `Contenu point fort par default`,
      image: defaultImage,
      image2: defaultImage,
    };

    let pointReassurance = {
      name: `carte point de réassurance produit par défault`,
      title: `Titre point de réassurance par default`,
      description: `Description point de réassurance par default`,
      content: `Contenu point de réassurance par default`,
      image: defaultImage,
      image2: defaultImage,
    };

    let realisation = {
      name: `carte nos réalisations produit par défault`,
      image: defaultImage,
    };

    let histoireTherapy = {
      name: `carte histoire de thérapie produit par défault`,
      title: "titre histoire de thérapie",
      description: `Description histoire de thérapie par default`,
      image: defaultImage,
    };

    let versionImages = {
      name: `carte Liste d'images`,
      image: defaultImage,
    };

    let listPointFort = await createList(pointfort, "point fort", "icone");

    let listPointReassurance = await createList(
      pointReassurance,
      "point de réassurance",
      "icone"
    );

    let nosRealisations = await createList(
      realisation,
      "nos réalisations",
      "carré"
    );

    let jetList = await createList(
      histoireTherapy,
      "histoire de thérapie",
      "carré"
    );

    let imagesProduits = await createList(
      versionImages,
      "autre images",
      "carré"
    );

    const defaultjetSection = {
      name: "Section histoire de thérapie produit ",
      image: [defaultImage],
      resolution: "Video",
      list: jetList.listFR.data.data.id,
    };

    let jetSection = await API.post("/api/sections", {
      data: defaultjetSection,
    });

    let couleurHabillage = {
      name: "Couleur Produit",
      image: defaultImage,
    };

    let listCouleurHabillage = await createList(
      couleurHabillage,
      "couleur habillage"
    );

    let couleurCuve = {
      name: "Couleur Produit",
      image: defaultImage,
    };

    let listCouleurCuve = await createList(couleurCuve, "couleur cuve");

    let defaultSEOFR = {
      title: "titre SEO produit par defaut",
      description: "SEO description produit par defaut",
      ogimage: defaultImage,
    };

    let SEOfr = await API.post("/api/seos", {
      data: defaultSEOFR,
    });

    const defaultBanner = {
      name: "Banniére produit ",
      title: "banniere par default",
      subtitle: "soustitre par default",
      image: [defaultImage],
    };

    let bannerfr = await API.post("/api/sections", {
      data: defaultBanner,
    });

    let defaultProductFr = {
      name: "Produit par défaut",
      type: 0,
      collection: 0,
      places: 1,
      onstock: 0,
      price: "10 000",
      ficheTechnique: 444,
      banner: bannerfr.data.data.id,
      jetCard: jetSection.data.data.id,
      pointfort: listPointFort.listFR.data.data.id,
      colors: listCouleurHabillage.listFR.data.data.id,
      cuveColors: listCouleurCuve.listFR.data.data.id,
      pointreassurance: listPointReassurance.listFR.data.data.id,
      realisation: nosRealisations.listFR.data.data.id,
      versionImages: imagesProduits.listFR.data.data.id,
      image: defaultImage,
      image2: defaultImage,
      seo: SEOfr.data.data.id,
    };

    const productFr = await API.post("/api/products", {
      data: defaultProductFr,
    });

    await API.put(`/api/products/${productFr.data.data.id}`, {
      data: {
        path: productUrlRewrite(
          productFr.data.data.id,
          productFr.data.data.attributes.name
        ),
      },
    });

    dispatch(
      handleInfo("Produit par défaut Ajouté (Vous pouvez le modifier) ", 1)
    );
    dispatch(getAllProducts());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updateProduct = (id, product) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    await API.put(`/api/products/${id}`, { data: product });
    dispatch(handleInfo("Produit Modifié", 1));
    dispatch(getProductItem(id));
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};
