import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import AddNewShowRoom from "../../Components/Showrooms/AddNewShowRoom";
import { getAllShowrooms } from "../../REDUX/actions/revendeur";

import ShowRoomList from "../../Components/Showrooms/ShowRoomList";

const ShowRoomsView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllShowrooms());
    // eslint-disable-next-line
  }, []);
  const { showroomsData, showRoomsDataLoading } = useSelector(
    (state) => state.showRoomsReducer
  );

  return showRoomsDataLoading || !showroomsData ? (
    <Loading />
  ) : (
    <>
      {/* <PageCard page={ActivePage} collapsed /> */}
      <AddNewShowRoom />
      <ShowRoomList />
    </>
  );
};

export default ShowRoomsView;
