import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import CustomButton from "../Common/CustomButton";
import { useNavigate } from "react-router-dom";
import { deletePartenaire } from "../../REDUX/actions/partenaire";

const PartenaireCard = ({ partenaire, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deletePartenaire(partenaire.id));
  };

  const showModal = () =>
    dispatch(
      openModal({
        type: "delete",
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Êtes-vous sûr(e) de vouloir supprimer le partenaire ?",
        callBack: handleDelete,
      })
    );

  const goTo = () => {
    navigate(`/partenaire/${partenaire.id}`, { state: { id: partenaire.id } });
  };

  return (
    <div
      className={`w-98 mx-auto my-2 row py-4 align-items-center rounded-10 ${
        index % 2 === 0 ? "bg-lightGrey" : ""
      }`}
    >
      <div className="col-4 text-start">{partenaire?.attributes.name}</div>
      <div className="col-5 text-start">
        {partenaire?.attributes.adress.data.attributes.fullAdress}
      </div>
      <div className="col-3 text-start row justify-content-start">
        <div className="col-6">
          <CustomButton
            buttonText={"Modifier"}
            iconStart="pencil-fill"
            callBack={goTo}
          />
        </div>
        <div className="col-6">
          <CustomButton
            buttonText={"Supprimer"}
            type="red"
            iconStart="trash3-fill"
            callBack={showModal}
          />
        </div>
      </div>
    </div>
  );
};

export default PartenaireCard;
