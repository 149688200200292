import React from "react";
import { Accordion } from "react-bootstrap";
import SectionInput from "../sectioninput/SectionInput";
import SEOInputs from "./SEOInputs";

const PageCard = ({ page, collapsed }) => {
  const banner = page.attributes.sections?.data[0] || [];

  return (
    <div className="row">
      <div className="col-12">
        <Accordion
          className="card  my-4 text-start rounded-10 bg-white shadowed"
          defaultActiveKey={"0"}
        >
          <Accordion.Item
            className="card-body accordionCustom rounded-10 p-0"
            eventKey="0"
          >
            <Accordion.Header>
              <div className=" f24">Séction {page.attributes.name}</div>
            </Accordion.Header>
            <Accordion.Body>
              {banner.attributes ? (
                <SectionInput
                  titleBlack={banner?.attributes?.name}
                  sectionData={banner.attributes}
                  sectionId={banner.id}
                  type="page"
                  withSeo
                  parentId={page.id}
                  shadowed={false}
                  image2LabelInput="Image du Menu"
                />
              ) : null}

              {page?.attributes?.sections?.data.map((section, index) =>
                index !== 0 ? (
                  <SectionInput
                    shadowed={false}
                    titleBlack={`Section ${index} : ${section.attributes.name}`}
                    key={index}
                    sectionData={section.attributes}
                    sectionId={section.id}
                  />
                ) : null
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/* <PageSuppSection page={page} collapsed={collapsed} /> */}
          <SEOInputs
            seo={page.attributes.seo?.data}
            pageId={page.id}
            title={page.attributes.name}
            collapsed={collapsed}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default PageCard;
