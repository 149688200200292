import React from "react";
import { useSelector } from "react-redux";
import CustomButton from "../Common/CustomButton";
import LoadingButton from "../Common/LoadingButton";

const ProductSubmitButton = ({ handleUpdateProduct, productInputs }) => {
  const { productsLoading } = useSelector((state) => state.productsReducer);

  return (
    <div className="row justify-content-center">
      <div className="col-6 mt-5">
        <CustomButton
          buttonText={productsLoading ? <LoadingButton /> : "Modifier"}
          iconStart={productsLoading ? "" : "pencil-fill"}
          callBack={handleUpdateProduct}
          disabled={
            !productInputs.name ||
            !productInputs.description ||
            !productInputs.subtitle ||
            !productInputs.price ||
            productInputs.type === undefined ||
            productInputs.collection === undefined ||
            productInputs.places === undefined ||
            productInputs.onstock === undefined
          }
        />
      </div>
    </div>
  );
};

export default ProductSubmitButton;
