import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addShowroom, getAllShowrooms } from "../../REDUX/actions/revendeur";
import CustomButton from "../Common/CustomButton";
import EmptyList from "../Common/EmptyList";
import InputText from "../sectioninput/formelement/InputText";
import ShowRoomCard from "./ShowRoomCard";

const ShowRoomList = () => {
  const dispatch = useDispatch();
  const [filter, setfilter] = useState("");

  const { showroomsData } = useSelector((state) => state.showRoomsReducer);
  const handleAddShowroom = () => {
    dispatch(addShowroom());
  };
  const filterShowRoom = () => {
    dispatch(getAllShowrooms(filter));
  };

  return (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des agences</div>
      <div className="row align-items-center mt-2">
        <div className="col-9 d-flex align-items-center">
          <InputText
            flag={false}
            placeholder="rechercher une agence"
            handleChange={setfilter}
          />
          <div className="ms-3 col-1">
            <CustomButton
              iconStart={"search"}
              callBack={() => filterShowRoom()}
            />
          </div>
        </div>
        <div className="col-3">
          <CustomButton
            buttonText={"Ajouter une agence"}
            iconStart={"plus-lg"}
            callBack={handleAddShowroom}
          />
        </div>
      </div>
      <div className="row mt-5 w-98 m-auto">
        <div className="col-4 f16 bolder text-start">Nom</div>
        <div className="col-5 f16 bolder text-start">Adresse</div>
        <div className="col-3 f16 bolder text-start">Actions</div>
      </div>
      <div className="mt-5">
        {showroomsData.length ? (
          showroomsData.map((showroom, index) => (
            <ShowRoomCard showroom={showroom} key={index} index={index} />
          ))
        ) : (
          <EmptyList text="Ajouter votre premiere agence" />
        )}
      </div>
    </div>
  );
};

export default ShowRoomList;
