const initialState = {
  eventsLoading: false,
  events: null,
  eventItem: null,
  error: null,
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EVENTS":
      return { ...state, events: action.payload, eventsLoading: false };
    case "FETCH_EVENT_ITEM":
      return { ...state, eventItem: action.payload, eventsLoading: false };
    case "EVENT_LOADING":
      return { ...state, eventsLoading: true };
    default:
      return state;
  }
};
