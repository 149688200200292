import React, { useEffect } from "react";
import VerticalBarChart from "../../Components/Dashboard/Chart/VerticalBarChart";
import ConsulteCard from "../../Components/Dashboard/ConsulteCard";
import { ReactComponent as ArticleIcon } from "../../images/Dashboard/Article.svg";
import { ReactComponent as FileIcon } from "../../images/Dashboard/FileIcon.svg";
import { ReactComponent as ProductIcon } from "../../images/Dashboard/ProductIcon.svg";
import { ReactComponent as PageIcon } from "../../images/Dashboard/PageIcon.svg";
import DoughnutChart from "../../Components/Dashboard/Chart/DoughnutChart";
import { useDispatch, useSelector } from "react-redux";
import { getNumbers } from "../../REDUX/actions/stats";
import Loading from "../../Components/Common/Loading";

const DashboardView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNumbers()); // eslint-disable-next-line
  }, []);

  const { dbStats, statsLoading } = useSelector((state) => state.statsReducer);

  return statsLoading || !dbStats ? (
    <Loading />
  ) : (
    <div className="">
      <div className="row">
        <div className="col-6 d-flex">
          <VerticalBarChart
            Title="Nombres visiteurs"
            minValue={0}
            maxValue={25000}
            labels={[15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]}
            ActuelDatasets={[1000, 5000, 9000, 20000, 10000, 15000, 4000, 12000, 5000, 9000, 20000]}
            LastMonthDatasets={[800, 4500, 8500, 15000, 9500, 14800, 3400, 9000, 4500, 8900, 17000]}
          />
        </div>
        <div className="col-6 d-flex">
          <DoughnutChart Title="Genre" Homme="20184" Femme="4352" NB="1267" />
        </div>
      </div>

      <div className="row pt-4 pb-2">
        <div className="col-5 d-flex">
          <VerticalBarChart
            Title="Vue d'articles"
            minValue={0}
            maxValue={25000}
            labels={[15, 16, 17, 18, 19, 20, 21, 22]}
            ActuelDatasets={[1000, 5000, 9000, 20000, 10000, 15000, 4000, 12000]}
            LastMonthDatasets={[800, 4500, 8500, 15000, 9500, 14800, 3400, 9000]}
          />
        </div>
        <div className="col-7">
          <div className="row">
            <div className="col-6">
              <ConsulteCard Title="Nombre d’articles actuels" Count={dbStats.articleNumber} Icon={ArticleIcon} FooterText="Consulter les articles" LinkTo="/blog" ColorCard="#C0392B" />
            </div>
            <div className="col-6">
              <ConsulteCard Title="Nombre de fichiers " Count={dbStats.filesNumber} Icon={FileIcon} FooterText="Consulter les fichiers" LinkTo="/files" ColorCard="#9B59B6" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <ConsulteCard Title="Nombre de produits" Count={dbStats.productsNumber} Icon={ProductIcon} FooterText="Consulter les produits" LinkTo="/products" ColorCard="#1ABC9C" />
            </div>
            <div className="col-6">
              <ConsulteCard Title="Nombre de pages actuelles" Count={dbStats.pagesNumber} Icon={PageIcon} FooterText="Consulter les pages" LinkTo="/pages" ColorCard="#2980B9" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
