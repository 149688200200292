import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VerticalBarChart = ({ Title, minValue, maxValue, labels, ActuelDatasets, LastMonthDatasets }) => {
  /*
    NOTE: PLUGIN BUTTON LEFT RIGHT TO SCROLL
     */
  /*
    const plugins = [{
        afterEvent(chart, args) {
            const { ctx, canvas, chartArea: {left, right, top, bottom, width, height} } = chart;

            canvas.addEventListener('mousemove', (event) => {
                const x = args.event.x;
                const y = args.event.y;

                if (x >= left - 15 && x <= left + 15 && y >= height + top - 15 && y <= height + top + 15) {
                    canvas.style.cursor = 'pointer';
                } else if (x >= right - 15 && x <= right + 15 && y >= height + top - 15 && y <= height + top + 15){
                    canvas.style.cursor = 'pointer';
                }else {
                    canvas.style.cursor = 'default';
                }
            })

            canvas.addEventListener('click', (event) => {
                const rect = canvas.getBoundingClientRect();
                const x = event.clientX - rect.left;
                const y = event.clientY - rect.top;
                if (x >= left - 20 && x <= left + 20 && y >= height + top - 20 && y <= height + top + 20) {
                    chart.options.scales.x.min = chart.options.scales.x.min - 7;
                    chart.options.scales.x.max = chart.options.scales.x.max - 7;
                    if (chart.options.scales.x.min <= 0) {
                        chart.options.scales.x.min = 0;
                        chart.options.scales.x.max = 6;
                    }
                    console.log('LEFT');
                }

                if (x >= right - 20 && x <= right + 20 && y >= height + top - 20 && y <= height + top + 20) {
                    chart.options.scales.x.min = chart.options.scales.x.min + 7;
                    chart.options.scales.x.max = chart.options.scales.x.max + 7;
                    if (chart.options.scales.x.max >= data.datasets[0].data.length) {
                        chart.options.scales.x.min = data.datasets[0].data.length - 7;
                        chart.options.scales.x.max = data.datasets[0].data.length;
                    }
                    console.log('RIGHT');
                }

                chart.update();

            })

        },

        afterDraw(chart, args, pluginOptions) {
            const { ctx, chartArea: {left, right, top, bottom, width, height} } = chart;

            class CircleChevron {
                draw(ctx, x1, pixel) {
                    const angle = Math.PI /180;
                    ctx.beginPath();
                    ctx.lineWidth = 3;
                    ctx.strokeStyle = 'rgba(102, 102, 102, 0.5)';
                    ctx.fillStyle = 'white';
                    ctx.arc(x1, height + top, 15, angle * 0, angle * 360, false);
                    ctx.stroke();
                    ctx.fill();
                    ctx.closePath();

                    // Chevron Arrow
                    ctx.beginPath();
                    ctx.lineWidth = 3;
                    ctx.strokeStyle = 'rgba(255, 26, 104, 1)';
                    ctx.moveTo(x1 + pixel, height + top - 7.5);
                    ctx.lineTo(x1 - pixel, height + top);
                    ctx.lineTo(x1 + pixel, height + top + 7.5);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            let drawCircleLeft = new CircleChevron();
            drawCircleLeft.draw(ctx, left, 5);

            let drawCircleRight = new CircleChevron();
            drawCircleRight.draw(ctx, right, -5);
        }
    }]
     */

  const options = {
    type: "bar",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          boxWidth: 5,
        },
      },
      title: {
        display: true,
        position: "top",
        align: "start",
        text: Title,
        font: {
          weight: "bold",
          size: "20",
        },
      },
      layout: {
        margin: 5,
        padding: {
          right: 10,
        },
      },
    },
    scales: {
      x: {
        min: 0,
        max: 12,
      },
      y: {
        min: minValue,
        max: maxValue,
        ticks: {
          // forces step size to be 50 units
          stepSize: 5000,
        },
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Actuel",
        data: ActuelDatasets,
        backgroundColor: "#8E44AD",
        barThickness: 10,
        maxBarThickness: 15,
        minBarLength: 2,
        borderColor: "rgba(0, 0, 0, 0)",
        borderWidth: "3",
        borderRadius: 5,
        order: 15,
      },
      {
        label: "Mois dernier",
        data: LastMonthDatasets,
        backgroundColor: "#E67E22",
        barThickness: 10,
        maxBarThickness: 15,
        minBarLength: 2,
        borderColor: "rgba(0, 0, 0, 0)",
        borderWidth: "3",
        borderRadius: 5,
        order: 15,
      },
    ],
  };

  return (
    <div className="card w-100 rounded-10">
      <div className="card-body m-3 mt-0 mb-3">
        <div className="align-items-center h-100 position-relative">
          <select className="form-select position-absolute w-25" style={{ right: 0, border: "none" }} aria-label="select month">
            <option value="janvier" selected>
              Janvier
            </option>
            <option value="février">Février</option>
            <option value="mars">Mars</option>
          </select>
          <Bar options={options} data={data} /*plugins={plugins}*/ />
        </div>
      </div>
    </div>
  );
};

export default VerticalBarChart;
