const initialState = {
  partenairesLoading: false,
  partenairesData: null,
  partenaireItem: null,
  error: null,
};

export const partenairesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PARTENAIRES":
      return {
        ...state,
        partenairesData: action.payload,
        partenairesLoading: false,
      };
    case "FETCH_PARTENAIRE_ITEM":
      return {
        ...state,
        partenaireItem: action.payload,
        partenairesLoading: false,
      };
    case "PARTENAIRE_LOADING":
      return { ...state, partenairesLoading: true };
    default:
      return state;
  }
};
