import API from "../../Api/Api.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: "USERS_LOADING" });
  try {
    let { data } = await API.post("/api/auth/local", credentials);
    localStorage.setItem("token", `Bearer ${data.jwt}`);
    localStorage.setItem("user", JSON.stringify(data.user));
    dispatch({ type: "LOGIN", payload: data.user });
    window.location.reload();
    dispatch(handleInfo("Bonjour", 1));
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.error?.name === "ValidationError") {
      dispatch({
        type: "USER_ERROR",
        payload: "E-mail ou mot de passe incorrect",
      });
    } else {
      dispatch({ type: "USER_ERROR", payload: "Erreur réseau" });
    }
    console.log(error);
  }
};

export const Check = () => async (dispatch) => {
  if (token) {
    API.defaults.headers.common["Authorization"] = token;
    dispatch({ type: "USERS_LOADING" });
    try {
      let { data } = await API.get("/api/users/me");

      dispatch({ type: "LOGIN", payload: data });
    } catch (error) {
      if (error.response?.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.reload(false);
        dispatch({ type: "UNAUTHORIZED" });
      } else {
        dispatch({ type: "USER_ERROR", payload: "Erreur réseau" });
      }
      console.log(error);
    }
  } else {
    localStorage.clear();

    dispatch({ type: "UNAUTHORIZED" });
  }
};

export const getFullUser = (id) => async (dispatch) => {
  const query = qs.stringify(
    {
      populate: ["role", "photo"],
    },

    {
      encodeValuesOnly: true,
    }
  );
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "USERS_LOADING" });
  try {
    let { data } = await API.get(`/api/users/${id}?` + query);
    dispatch({ type: "SUCCESS_AUTH", payload: data });
  } catch (error) {
    dispatch({ type: "USER_ERROR", payload: "Erreur réseau" });

    console.log(error);
  }
};

export const updateCredentials = (newUser, id) => async (dispatch) => {
  try {
    await API.post("/api/auth/local", {
      identifier: newUser.email,
      password: newUser.oldPassword,
    });
    API.defaults.headers.common["Authorization"] = token;

    dispatch({ type: "USERS_LOADING" });
    try {
      await API.put(`/api/users/${id}?`, {
        email: newUser.newEmail,
        password: newUser.newPassword,
      });

      dispatch(getFullUser(id));
      dispatch(handleInfo("Utilisateur mis à jour", 1));
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));

      console.log(error);
    }
  } catch (error) {
    dispatch(handleError("Ancien Mot de passe incorrect", 1));
    console.log(error);
  }
};

export const updateUser = (newUser, id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.put(`/api/users/${id}?`, {
      username: newUser.username,
      firstname: newUser.firstname,
      lastname: newUser.lastname,
    });

    dispatch(getFullUser(id));
    dispatch(handleInfo("Utilisateur mis à jour", 1));
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));

    console.log(error);
  }
};

export const getAllUsers = (newUser, id) => async (dispatch) => {
  const query = qs.stringify(
    {
      populate: ["role", "photo"],
    },

    {
      encodeValuesOnly: true,
    }
  );
  API.defaults.headers.common["Authorization"] = token;

  try {
    let { data } = await API.get(`/api/users?` + query);

    dispatch({ type: "FETCH_USERS", payload: data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));

    console.log(error);
  }
};

export const addUser = (newUser) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.post(`/api/users`, newUser);
    dispatch(getAllUsers());

    dispatch(handleInfo("Utilisateur ajouté", 1));
  } catch (error) {
    if (error.response.data.error.message === "Email already taken") {
      dispatch(handleError("Email est déja existant", 1));
    } else {
      dispatch(handleError("Erreur réseau", 1));
    }
    console.log(error);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.delete(`/api/users/${id}`);
    dispatch(getAllUsers());

    dispatch(handleInfo("Utilisateur supprimé", 1));
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));

    console.log(error);
  }
};

export const changeUserRole = (newRole, id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.put(`/api/users/${id}`, { isAdmin: newRole });
    dispatch(getAllUsers());

    dispatch(handleInfo("Rôle changé", 1));
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));

    console.log(error);
  }
};
