import React from "react";

import PageList from "../../Components/PageCard/PageList";

const PagesView = () => {
  return (
    <div>
      <PageList />
    </div>
  );
};

export default PagesView;
