import React from "react";

import UserList from "../../Components/Users/UserList";

const UsersView = () => {
  return (
    <div className="container-fluid bg-white shadowed rounded-10 p-5">
      <UserList />
    </div>
  );
};

export default UsersView;
