import API from "../../Api/Api.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
import { realTimeInsertData } from "./pages.js";
import { getProductItem } from "./products.js";
import { pageQueryAll } from "./Queries.js";
import { getShowroomItem } from "./revendeur.js";
import { getPartenaireItem } from "./partenaire.js";
const token = localStorage.getItem("token");

export const realTimeUpdateItem = (id) => async (dispatch) => {
  const query = qs.stringify(
    {
      populate: {
        cards: {
          populate: ["image", "image2", "imageMobile", "imageMobile2"],
        },
        section: {
          populate: "page",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );

  try {
    let { data } = await API.get(`api/lists/${id}?${query}`);
    if (data.data.attributes.section.data?.attributes.page.data) {
      const pageId = data.data.attributes.section.data.attributes.page.data.id;
      let newPage = await API.get(`api/pages/${pageId}?` + pageQueryAll);
      dispatch(realTimeInsertData(newPage.data.data));
      dispatch({ type: "FETCH_LIST", payload: data.data });
    } else {
      dispatch(getJetCard());
    }
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));

    console.log(error);
  }
};

export const updateCard =
  (cardData, cardId, listId, productId, magasinId, partenaireId) =>
  async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;

    dispatch({ type: "LIST_LOADING" });
    try {
      await API.put(`/api/cards/${cardId}`, { data: cardData });

      if (productId) {
        await dispatch(getProductItem(productId, true));
      } else if (magasinId) {
        await dispatch(getShowroomItem(magasinId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else if (partenaireId) {
        await dispatch(getPartenaireItem(partenaireId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else {
        await dispatch(realTimeUpdateItem(listId));
      }
      dispatch(handleInfo("Carte mis à jour", 1));
    } catch (error) {
      dispatch(handleError("Erreur lors de la mise à jour de la carte", 1));
      console.log(error);
    }
  };

export const addCard =
  (defaultCard, listId, productId, magasinId, partenaireId) =>
  async (dispatch) => {
    const query = qs.stringify(
      {
        populate: {
          cards: {
            populate: ["image", "image2", "imageMobile", "imageMobile2"],
            sort: ["order:asc"],
          },
        },
      },

      {
        encodeValuesOnly: true,
      }
    );
    try {
      dispatch({ type: "LIST_LOADING" });
      let list = await API.get(`api/lists/${listId}?${query}`);

      let cards = list.data.data.attributes.cards.data;
      let cardFR = await API.post("/api/cards", {
        data: defaultCard,
      });
      await API.put(`api/lists/${listId}`, {
        data: { cards: [...cards, cardFR.data.data] },
      });

      if (productId) {
        await dispatch(getProductItem(productId, true));
      } else if (magasinId) {
        await dispatch(getShowroomItem(magasinId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else if (partenaireId) {
        await dispatch(getPartenaireItem(partenaireId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else {
        await dispatch(realTimeUpdateItem(listId));
      }
      dispatch(handleInfo("Carte ajouté ", 1));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));

      console.log(error);
    }
  };

export const deleteCard =
  (cardId, listId, productId, magasinId, partenaireId) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    dispatch({ type: "LIST_LOADING" });
    try {
      await API.delete(`/api/cards/${cardId}`);

      if (productId) {
        await dispatch(getProductItem(productId, true));
      } else if (magasinId) {
        await dispatch(getShowroomItem(magasinId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else if (partenaireId) {
        await dispatch(getPartenaireItem(partenaireId, true));
        dispatch({ type: "LIST_DONE_LOADING" });
      } else {
        await dispatch(realTimeUpdateItem(listId));
      }
      dispatch(handleInfo("Carte supprimé", 1));
    } catch (error) {
      dispatch(handleError("Erreur lors de la mise à jour de la carte", 1));
      console.log(error);
    }
  };

export const getIndependantList = () => async (dispatch) => {
  const query = qs.stringify(
    {
      populate: {
        cards: {
          populate: ["image", "image2", "imageMobile", "imageMobile2"],
          sort: ["order:asc"],
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  API.defaults.headers.common["Authorization"] = token;
  let id = "120";

  dispatch({ type: "LIST_LOADING" });
  try {
    const { data } = await API.get(`/api/lists/${id}?${query}`);
    dispatch({ type: "INDEPENDANT_LIST", payload: data.data });
  } catch (error) {
    dispatch(handleError("Erreur Réseau", 1));
    console.log(error);
  }
};

export const getJetCard = () => async (dispatch) => {
  const query = qs.stringify(
    {
      populate: {
        image: {
          populate: "*",
        },
        image2: { populate: ["*"] },

        list: {
          populate: {
            cards: {
              populate: ["image", "image2", "imageMobile", "imageMobile2"],
            },
          },
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  let id = "642";

  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "LIST_LOADING" });
  try {
    const { data } = await API.get(`/api/sections/${id}?${query}`);
    dispatch({ type: "GET_JET_CARD", payload: data.data });
  } catch (error) {
    dispatch(handleError("Erreur Réseau", 1));
    console.log(error);
  }
};
