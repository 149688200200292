import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import InputText from "../../Components/sectioninput/formelement/InputText";
import {
  getContactItem,
  // sendEmail,
  setContactViewed,
} from "../../REDUX/actions/contact";
import moment from "moment";
// import CustomButton from "../../Components/Common/CustomButton";

const ContactItemView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { contactLoading, contactItem } = useSelector(
    (state) => state.contactsReducer
  );

  useEffect(() => {
    dispatch(getContactItem(params.id));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (contactItem) {
      if (!contactItem.attributes.viewedBy.includes(user.firstName)) {
        dispatch(
          setContactViewed(
            contactItem.id,
            `${contactItem.attributes.viewedBy}, ${
              user.firstName
            } le ${moment().format("MM-DD-YYYY")}`
          )
        );
      }
    } // eslint-disable-next-line
  }, [contactItem]);

  // const handleMailing = () => {
  //   dispatch(sendEmail(contactItem.id));
  // };

  return contactLoading || !contactItem ? (
    <Loading />
  ) : (
    <div className="container-fluid bg-white shadowed p-5 rounded-10 ">
      <div className="row f24 align-items-center mb-2">Donnée du client</div>
      <div className="row f14 align-items-center mb-5">
        {contactItem.attributes.viewedBy.replace(/,/, " ")}
      </div>
      <div className="row">
        <div className="col-6">
          {contactItem.attributes.firstName ? (
            <InputText
              labelinput={"Prénom"}
              readOnly
              value={contactItem.attributes.firstName}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.lastName ? (
            <InputText
              labelinput={"Nom"}
              readOnly
              value={contactItem.attributes.lastName}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.email ? (
            <InputText
              labelinput={"E-mail"}
              readOnly
              value={contactItem.attributes.email}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.phoneNumber ? (
            <InputText
              labelinput={"Téléphone"}
              readOnly
              value={contactItem.attributes.phoneNumber}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.adresse ? (
            <InputText
              labelinput={"Adresse"}
              readOnly
              value={contactItem.attributes.adresse}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.postalCode ? (
            <InputText
              labelinput={"Code Postal"}
              readOnly
              value={contactItem.attributes.postalCode}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.city ? (
            <InputText
              labelinput={"Ville"}
              readOnly
              value={contactItem.attributes.city}
              flag={false}
            />
          ) : null}
        </div>
        <div className="col-6">
          {contactItem.attributes.type ? (
            <InputText
              labelinput={"Type de la demande"}
              readOnly
              value={contactItem.attributes.type}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.information ? (
            <InputText
              labelinput={"Information demandé"}
              readOnly
              value={contactItem.attributes.information}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.interestedIn ? (
            <InputText
              labelinput={"Gamme"}
              readOnly
              value={contactItem.attributes.interestedIn}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.label ? (
            <InputText
              labelinput={"Modele"}
              readOnly
              value={contactItem.attributes.label}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.buyPeriod ? (
            <InputText
              labelinput={"Période d'achat potentielle"}
              readOnly
              value={contactItem.attributes.buyPeriod}
              flag={false}
            />
          ) : null}
          {contactItem.attributes.message ? (
            <>
              <label className="f16 text-start w-100 mb-1">Message</label>
              <textarea
                className="styleInput readOnlyArea w-100 rounded-3"
                readOnly
                value={contactItem.attributes.message}
              />
            </>
          ) : null}
          {/* <div className="mt-5"></div> <CustomButton buttonText={"Transmettre"} callBack={handleMailing}></CustomButton> */}
        </div>
      </div>
    </div>
  );
};

export default ContactItemView;
