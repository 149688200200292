import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSection } from "../../REDUX/actions/section";
import CustomButton from "../Common/CustomButton";
import "./SectionPanel.css";

const SectionPanel = ({ eventItemForm, type = "articles", backRoute }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backTo = () => {
    navigate(backRoute === "articles" ? "/blog" : "/events");
  };
  const [withImage, setwithImage] = useState(false);

  const allSectionIds = eventItemForm.attributes.sections.data.map(
    (el) => el.id
  );

  const addNewSection = () => {
    dispatch(addSection(type, eventItemForm.id, allSectionIds, withImage));
  };

  return (
    <div className="container-fluid bg-white rounded-10 p-5 shadowed">
      <h2 className="mb-0 f24 fw-normal text-start">Boîte à outils</h2>
      <div className="row mt-3">
        <div className="col-12">
          <CustomButton
            outlined
            buttonText={
              type === "articles"
                ? "revenir aux articles"
                : "revenir aux événements"
            }
            iconStart="arrow-left"
            callBack={backTo}
          />
        </div>
        <div className="col-6">
          {/* <CustomButton outlined buttonText="Ajouter Section" iconStart="arrow-left" /> */}
        </div>
      </div>
      <div className="row addSection mt-3 bold f20" onClick={addNewSection}>
        Ajouter une Section
      </div>
      <div className="row mt-3">
        <div className="col-12 text-center">
          <p>Avec Image</p>
          <Form.Check
            type="switch"
            color="primary"
            id="custom-switch"
            onChange={(e) => setwithImage(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionPanel;
