import React from "react";
import './Dashboard.css'
import {Link} from "react-router-dom";

const ConsulteCard = ({ Title, Count, Icon, FooterText, LinkTo, ColorCard }) => {
        return (
            <div className="card w-100 rounded-10">
                <div className="card-body m-1 mt-4 mb-0">
                    <div className="row hoverConsulteDrawerLitleScreen">
                        <div className="col-auto align-self-center">
                            <Icon className="iconConsulte img-fluid w-100" alt=""/>
                        </div>
                        <div className="col">
                            <div className="card-block px-2">
                                <h2 className="card-title fw-normal f16 text-start">{Title}</h2>
                                <p className="card-text f48 text-start">{Count}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={LinkTo} >
                    <div className="card-footer text-muted rounded-bottom-10" style={{backgroundColor: ColorCard}}>
                        <div className="row">
                            <div className="col-auto">
                                <p className="card-text text-white f16 text-start">{FooterText}</p>
                            </div>
                            <div className="col">
                                <i className="bi bi-chevron-right text-end text-white"></i>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    };

        export default ConsulteCard;
