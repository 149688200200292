import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

const Paggination = ({ action = () => {}, pages, pageNumber }) => {
  const dispatch = useDispatch();
  const [selectedPage, setselectedPage] = useState(pageNumber);
  const pagginate = (targetPage) => {
    if (targetPage !== 0 && targetPage !== pages + 1) {
      setselectedPage(targetPage);

      dispatch(action(targetPage));
    }
  };

  useEffect(() => {
    setselectedPage(pageNumber);
  }, [pageNumber]);

  return !pages ? null : (
    <div className="d-flex  w-98 mt-4 align-items-center  justify-content-between h20">
      <div className="d-flex gap-3">
        <button
          onClick={() => pagginate(1)}
          className="t02 rounded-10 px-2 f16 customButtonorange d-flex h20 align-items-center"
        >
          <i className="bi bi-chevron-left"></i>
          <i className="bi bi-chevron-left"></i>
        </button>
        <button
          onClick={() => pagginate(selectedPage - 1)}
          className="t02 rounded-10 px-2 f16 customButtonorange d-flex h20 align-items-center"
        >
          <i className="bi bi-chevron-left me-2"></i>
          Précedent
        </button>
      </div>
      <div className="">
        <div>
          <strong>{selectedPage}</strong> /{" "}
          <span className="f18">{pages} pages</span>
        </div>{" "}
      </div>
      <div className="d-flex gap-3">
        <button
          onClick={() => pagginate(selectedPage + 1)}
          className="t02 rounded-10 px-2 f16 customButtonorange d-flex h20 align-items-center"
        >
          Suivant
          <i className=" bi bi-chevron-right ms-2"></i>
        </button>
        <button
          onClick={() => pagginate(pages)}
          className="t02 rounded-10 px-2 f16 customButtonorange d-flex h20 align-items-center"
        >
          <i className="bi bi-chevron-right"></i>
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Paggination;
