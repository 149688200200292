import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import EventsList from "../../Components/Events/EventsList";
import PageCard from "../../Components/PageCard/PageCard";
import { getAllEvents } from "../../REDUX/actions/events";
import { getPageByName } from "../../REDUX/actions/pages";
import { pageNames } from "../../Api/EndPoints";

const EventsView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPageByName(pageNames.events));
    dispatch(getAllEvents()); // eslint-disable-next-line
  }, []);

  const { pagesLoading, ActivePage } = useSelector(
    (state) => state.pagesReducer
  );
  const { events } = useSelector((state) => state.eventsReducer);

  return pagesLoading || !ActivePage || !events ? (
    <Loading />
  ) : (
    <>
      <PageCard page={ActivePage} collapsed />
      <EventsList />
    </>
  );
};

export default EventsView;
