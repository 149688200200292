import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, getAllProducts } from "../../REDUX/actions/products";
import CustomButton from "../Common/CustomButton";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import InputText from "../sectioninput/formelement/InputText";
import ProductCard from "./ProductCard";

const ProductList = () => {
  const dispatch = useDispatch();
  const [filter, setfilter] = useState("");

  const { productsLoading, products } = useSelector(
    (state) => state.productsReducer
  );
  const filterProducts = () => {
    dispatch(getAllProducts(filter));
  };
  const handleAddProduct = () => dispatch(addProduct());

  return !products ? (
    <Loading />
  ) : (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des produits</div>
      <div className="row align-items-center mt-2">
        <div className="col-9 d-flex align-items-center">
          <InputText
            flag={false}
            placeholder="rechercher un produit"
            handleChange={setfilter}
          />
          <div className="ms-3 col-1">
            <CustomButton
              iconStart={"search"}
              callBack={() => filterProducts(filter)}
            />
          </div>
        </div>
        <div className="col-3">
          <CustomButton
            buttonText={"Passez en langue principale"}
            iconStart={"plus-lg"}
            callBack={handleAddProduct}
          />
        </div>
      </div>
      {!productsLoading ? (
        <div className="mt-5 d-flex gap-5 flex-wrap justify-content-around">
          {products.length ? (
            products.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))
          ) : (
            <div className="w-100">
              <EmptyList text="Ajouter votre premier produit" />
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProductList;
