import React from "react";
import CustomButton from "../Common/CustomButton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { contactColor } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import { deleteContact } from "../../REDUX/actions/contact";

const ContactCard = ({ contact }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/contact/${contact.id}`);
  };
  const showDeleteModal = async () => {
    dispatch(
      openModal({
        type: "delete",
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Etes vous sûr de vouloir supprimer cette demande de contact ?",
        callBack: () => dispatch(deleteContact(contact.id)),
      })
    );
  };
  return (
    <div className={`row align-items-center mb-1 w-98 m-auto rounded-3 contactCard py-3 bg-${contactColor(contact)}`}>
      <div className="col-1 f16 bolder text-start">{moment(contact.attributes.createdAt).format("L")}</div>
      <div className="col-1 f16 bolder text-start">{contact.attributes.type}</div>
      <div className="col-3 f16 bolder text-start">{contact.attributes.email}</div>
      <div className="col-3 f16 bolder text-start">
        {contact.attributes.lastName} {contact.attributes.firstName}
      </div>
      <div className="col-1 f16 bolder text-start">{contact.attributes.viewedBy?.includes(",") ? "Vue" : "Nouveau"}</div>
      <div className="col-3 f16 bolder text-start d-flex gap-3">
        <div className="w-70 m-0">
          <CustomButton buttonText={"Consulter"} iconStart={"eye"} callBack={handleNavigate} />
        </div>
        <div className="w-20">
          <CustomButton iconStart={"trash"} type="red" callBack={showDeleteModal} />
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
