import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ArticleEventForm from "../../Components/ArticleEvent/ArticleEventForm";
import SectionTopPanel from "../../Components/ArticleEvent/SectionTopPanel";
import Loading from "../../Components/Common/Loading";
import EventsAssociatedShowrooms from "../../Components/Events/EventsAssociatedShowrooms";
import SEOInputs from "../../Components/PageCard/SEOInputs";
import SectionInput from "../../Components/sectioninput/SectionInput";
import { getEventItem } from "../../REDUX/actions/events";

const EventItemView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getEventItem(params.id));
    // eslint-disable-next-line
  }, []);

  const { articlesLoading, eventItem } = useSelector(
    (state) => state.eventsReducer
  );

  return articlesLoading || !eventItem ? (
    <Loading />
  ) : (
    <div>
      <div className="row justify-content-center align-items-start">
        <div className=" col-12 col-md-12">
          <Accordion className=" rounded-10 ">
            <SEOInputs
              seo={eventItem.attributes.seo.data}
              title={eventItem.attributes.title}
              collapsed
            />
            <div className="my-2"></div>
            <SectionInput
              sectionData={eventItem.attributes.banner.data.attributes}
              sectionId={eventItem.attributes.banner.data.id}
              titleBlack="Bannière de l'événement"
            />
            <div className="my-2"></div>
            <EventsAssociatedShowrooms eventItem={eventItem} />
            <div className="my-2"></div>
            <SectionTopPanel
              eventItemForm={eventItem}
              type={"events"}
              title={"Modifier l'événement"}
            />
          </Accordion>
        </div>
        {/* <div className=" col-12 col-md-4 ">
          <SectionTopPanel eventItemForm={eventItem} type={"events"} title={"Modifier l'événement"} />
        </div> */}
      </div>

      <ArticleEventForm eventItemForm={eventItem} type={"events"} />
    </div>
  );
};

export default EventItemView;
