import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./AddNewShowRoom.css";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../../REDUX/actions/notifications";

const AddNewShowRoom = () => {
  const dispatch = useDispatch();
  const mapContainer = useRef();

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || "pk.eyJ1Ijoic2FteWdoYXJzYWxsYWgiLCJhIjoiY2t6eTFjcW5uMDc1NzJubzZpYmxydHhhNSJ9.i2NSCdzPgg6WdVMWILsjeg";

  const [map, setmap] = useState({});
  const [maploaded, setmaploaded] = useState(false);
  const [showRooms, setshowRooms] = useState([]);
  const { showroomsData } = useSelector((state) => state.showRoomsReducer);

  useEffect(() => {
    try {
      setmaploaded(false);
      if (mapContainer.current) {
        setmap(
          new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [2.3279959262928855, 47.53462557858617],
            zoom: 5,
          })
            .addControl(
              new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                countries: "fr",
                language: "fr",
                marker: false,
              })
            )
            .on("load", () => {
              setmaploaded(true);
            })
        );
      }
    } catch (error) {
      dispatch(handleError("erreur de chargement de la carte", 1));
    }
    // eslint-disable-next-line
  }, [mapContainer.current]);

  useEffect(() => {
    setshowRooms(showroomsData);
  }, [showroomsData]);
  useEffect(() => {
    if (showRooms.length && maploaded) {
      // eslint-disable-next-line
      showRooms.map((point) => {
        new mapboxgl.Marker({ draggable: false, color: "#0F5467" }).setLngLat(point.attributes.adress.data.attributes.coordinates).addTo(map);
      });
    }
    // eslint-disable-next-line
  }, [maploaded, showRooms]);

  return (
    <div className="bg-white rounded-10 container-fluid my-4  shadowed">
      <div className="row align-items-center">
        <div id="map" ref={mapContainer} className="col-10"></div>
      </div>
    </div>
  );
};

export default AddNewShowRoom;
