import React from "react";
import ContactList from "../../Components/Contact/ContactList";

const ContactView = () => {
  return (
    <div className="container-fluid rounded-10 shadowed bg-white p-5">
      <ContactList />
    </div>
  );
};

export default ContactView;
