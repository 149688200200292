import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import SectionInput from "../../Components/sectioninput/SectionInput";

import "./PartenaireItemView.css";
import { Accordion } from "react-bootstrap";
import SEOInputs from "../../Components/PageCard/SEOInputs";
import Lists from "../../Components/Lists/Lists";
import { getAllProducts } from "../../REDUX/actions/products";
import { getAllEvents } from "../../REDUX/actions/events";
import { getPartenaireItem } from "../../REDUX/actions/partenaire";
import PartenaireForm from "../../Components/Partenaires/PartenaireForm";

const PartenaireItemView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const mapContainer = useRef();

  mapboxgl.accessToken =
    process.env.REACT_APP_MAPBOX_TOKEN ||
    "pk.eyJ1Ijoic2FteWdoYXJzYWxsYWgiLCJhIjoiY2wzMW5mM3gwMDA0ZTNkbGE3ZnZlemt5OSJ9.VB_YLnMOd9Z4YRp3AbW6_Q";

  const [map, setmap] = useState({});
  const [marker] = useState(new mapboxgl.Marker({ draggable: true }));
  const [maploaded, setmaploaded] = useState(false);
  const [partenaires, setPartenaires] = useState({});
  const [controls] = useState(
    new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: "fr,be,lu,ch,es,it,gb,pt,pl,dk,cz,gr",
      language: "fr",
      marker: false,
    }).on("result", ({ result }) => marker.setLngLat(result.center))
  );

  const { partenaireItem, partenairesLoading } = useSelector(
    (state) => state.partenairesReducer
  );

  useEffect(() => {
    dispatch(getPartenaireItem(params.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setmaploaded(false);
    if (mapContainer.current) {
      setmap(
        new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center:
            partenaires?.attributes?.adress?.data?.attributes?.coordinates,
          zoom: 6,
        })
          .addControl(controls)
          .on("load", () => {
            setmaploaded(true);
          })
      );
    }
    // eslint-disable-next-line
  }, [mapContainer.current]);

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllEvents());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPartenaires(partenaireItem);
    // eslint-disable-next-line
  }, [partenaireItem]);

  useEffect(() => {
    marker.remove();
    if (partenaires && maploaded) {
      marker
        .setLngLat(partenaires.attributes.adress.data.attributes.coordinates)
        .addTo(map);
    }
    // eslint-disable-next-line
  }, [maploaded, partenaires]);
  return !partenaireItem || partenairesLoading ? (
    <Loading />
  ) : (
    <>
      <SEOInputs
        seo={partenaireItem.attributes.seo.data}
        title={partenaireItem.attributes.name}
        collapsed
      />
      <div className="my-2"></div>

      <SectionInput
        sectionData={partenaireItem.attributes.banner.data.attributes}
        sectionId={partenaireItem.attributes.banner.data.id}
        titleBlack="Bannière du revendeur"
        redirect="/showroom"
      />
      <div className="my-2"></div>

      <div className="my-2"></div>
      <Accordion className={`text-start rounded-10 `}>
        <Accordion.Item
          className="card-body noBorder p-0 rounded-10"
          eventKey="0"
        >
          <Accordion.Header className="rounded-10">
            <h2 className="mb-0 f24 fw-normal">Liste d'images du partenaire</h2>
          </Accordion.Header>
          <Accordion.Body>
            <Lists
              listData={partenaireItem.attributes.photos_magasin.data}
              partenaireId={partenaireItem.id}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="bg-white rounded-10 container-fluid my-4 py-5 shadowed">
        <div className=" f24 text-start mb-4">Modifier Partenaire</div>
        <div className="formMapContainer ">
          <div className="showRoomFomItem ">
            <PartenaireForm marker={marker} partenaireItem={partenaireItem} />
          </div>
          <div className="mapContainer">
            <div className="f24 mb-4">Modifier l'emplacement du partenaire</div>
            <div id="map" ref={mapContainer} className="mapInItem"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartenaireItemView;
