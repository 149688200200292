import { productUrlRewrite } from "../../utils/utils.js";

const initialState = {
  productsLoading: false,
  products: null,
  productItem: null,
  productsIdAndNames: null,
};

export const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PRODUCTS_LOADING":
      return { ...state, productsLoading: true };
    case "FETCH_PRODUCTS":
      const productsIds = payload.map((el) => ({ value: `/produit/${productUrlRewrite(el.id, el.attributes.name)}`, text: (el.id, el.attributes.name) }));
      return { ...state, productsLoading: false, products: payload, productsIdAndNames: productsIds };
    case "FETCH_PRODUCT_ITEM":
      return { ...state, productsLoading: false, productItem: payload };
    default:
      return state;
  }
};
