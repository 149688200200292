import React, { useState } from "react";
import InputText from "../../Components/sectioninput/formelement/InputText";
import "./LoginView.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../REDUX/actions/users";
import Loading from "../../Components/Common/Loading";

const LoginView = () => {
  const dispatch = useDispatch();
  const [credentials, setcredentials] = useState({ identifier: "", password: "" });
  const { userLoading, error } = useSelector((state) => state.usersReducer);

  const handleChange = (value, champs) => {
    setcredentials({ ...credentials, [champs]: value });
  };
  const handleLogin = () => {
    dispatch(login(credentials));
  };

  return userLoading ? (
    <Loading />
  ) : (
    <div className="col-6  gap-4 w-40 d-flex flex-column align-items-center p-5 rounded-10 loginGlass">
      <div className="logoContainerLogin mb-4"></div>
      <p className=" bold f20">Connexion à votre espace Administrateur</p>
      <InputText labelinput="Email" white value={credentials.identifier} champs="identifier" handleChange={handleChange} flag={false} />
      <InputText labelinput="Mot de passe" white type="password" value={credentials.password} champs="password" handleChange={handleChange} flag={false} />
      {error ? <div className="text-red f14 text-start">{error}</div> : null}
      <button type="button" className="btn btn-primary w-100 text-white my-3" onClick={handleLogin}>
        Connexion
      </button>
      {/* <Link to="/login" className="mt-2 mb-2">
                Mot de passe oublié ?
              </Link> */}
    </div>
  );
};

export default LoginView;
