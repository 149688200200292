import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import Uploader from "../Common/Uploader";
import InputText from "../sectioninput/formelement/InputText";
import _ from "lodash";
import CustomButton from "../Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { updateSEO } from "../../REDUX/actions/section";
import LoadingButton from "../Common/LoadingButton";

const SEOInputs = ({ seo, title, pageId, collapsed }) => {
  const dispatch = useDispatch();
  const [image, setimage] = useState(seo?.attributes.ogimage.data);
  const [seoInputs, setseoInputs] = useState(
    _.omit(seo?.attributes, ["ogimage", "createdAt", "updatedAt", "locale"])
  );

  const handleChange = (value, champs) => {
    setseoInputs({ ...seoInputs, [champs]: value });
  };
  const { seoLoading } = useSelector((state) => state.pagesReducer);
  const handleImage = (imageSelected) => {
    setimage(imageSelected);
  };

  const handleSubmit = () => {
    dispatch(updateSEO(seo?.id, { ...seoInputs, ogimage: image?.id }, pageId));
  };

  return (
    <Accordion defaultActiveKey={collapsed ? "" : "0"}>
      <Accordion.Item
        className="card-body accordionCustom rounded-10 p-0"
        eventKey="0"
      >
        <Accordion.Header>
          <div className=" f24">SEO : {title} </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row align-items-center">
            <div className="col-4 d-flex flex-column align-items-center gap-5 mb-5">
              <Uploader
                labelinput="Og-Image"
                handleImage={handleImage}
                image={image}
              />
            </div>
            <div className="col ">
              <InputText
                labelinput="Titre"
                value={seoInputs.title}
                handleChange={handleChange}
                champs="title"
              />
              <InputText
                labelinput="Déscription"
                value={seoInputs.description}
                handleChange={handleChange}
                champs="description"
              />
              <InputText
                labelinput="URL Canonique"
                value={seoInputs.url}
                handleChange={handleChange}
                champs="url"
              />
            </div>
          </div>
          <CustomButton
            buttonText={seoLoading ? <LoadingButton /> : "Enregistrer"}
            iconStart={seoLoading ? "" : "check-lg"}
            callBack={handleSubmit}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SEOInputs;
