import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomButton from "../../Components/Common/CustomButton";
import Loading from "../../Components/Common/Loading";
import PageCard from "../../Components/PageCard/PageCard";
import { getPageItem } from "../../REDUX/actions/pages";
import { pageLink } from "../../utils/utils";
import "./PageItemView.css";
import { FRONT_SITE_URL } from "../../Api/EndPoints";

const PageItemView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getPageItem(params.id));
    // eslint-disable-next-line
  }, []);

  const { pagesLoading, ActivePage } = useSelector(
    (state) => state.pagesReducer
  );

  const viewInSite = (goTo) => {
    window.open(FRONT_SITE_URL + goTo);
  };

  return pagesLoading || !ActivePage ? (
    <Loading />
  ) : (
    <div>
      <div className="row justify-content-end consultButtonContainer">
        <div className="col-2 consultButton ">
          <CustomButton
            buttonText={"Consulter"}
            type="primary"
            iconStart="eye-fill"
            callBack={() => viewInSite(pageLink(ActivePage.attributes.name))}
          />
        </div>
      </div>
      <PageCard page={ActivePage} collapsed={true} />
    </div>
  );
};

export default PageItemView;
