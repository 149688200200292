import API from "../../Api/Api.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllPartenaires = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        name: {
          $containsi: filter,
        },
      },
      populate: ["adress"],
      sort: ["createdAt:desc"],
      pagination: {
        page: 1,
        pageSize: 200,
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  dispatch({ type: "PARTENAIRE_LOADING" });
  try {
    let { data } = await API.get("/api/partenaires?" + query);

    dispatch({ type: "FETCH_PARTENAIRES", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const getPartenaireItem = (id, noLoading) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id,
        },
      },
      populate: {
        banner: {
          populate: "*",
        },
        photos_magasin: {
          populate: { cards: { populate: ["image", "image2"] } },
        },

        adress: {
          populate: "*",
        },
        seo: {
          populate: "ogimage",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  if (!noLoading) {
    dispatch({ type: "PARTENAIRE_LOADING" });
  }
  try {
    let { data } = await API.get("/api/partenaires?" + query);

    dispatch({ type: "FETCH_PARTENAIRE_ITEM", payload: data.data[0] });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const addPartenaire = () => async (dispatch) => {
  console.log("first");
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "PARTENAIRE_LOADING" });
  try {
    let image = await API.get("api/upload/files");
    defaultImage = image.data[0]?.id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moins une image pour procéder", 1)
      );
    }
    const defaultBanner = {
      name: "Banniére partenaire ",
      title: "Nom du partenaire",
      subtitle: "sous titre",
      image: [defaultImage],
      imageMobile: [defaultImage],
    };

    let bannerfr = await API.post("/api/sections", {
      data: defaultBanner,
    });

    let defaultCard = {
      name: "carte Image partenaire",
      title: "titre image partenaire par défault",
      image: defaultImage,
    };

    let cardFR = await API.post("/api/cards", {
      data: defaultCard,
    });

    let defaultListFR = {
      name: "Liste d'image partenaire",
      cards: [cardFR.data.data.id],
      resolution: "carré",
    };

    let listFR = await API.post("/api/lists", {
      data: defaultListFR,
    });

    let defaultAdress = {
      fullAdress: "Numéro -- Rue --",
      postalCode: "*******",
      city: "Nice (par défaut)",
      country: "France (par défaut)",
      coordinates: [-0.370691110245275, 48.51969961538856],
    };

    let defaultPartenaire = {
      name: "Nom du partenaire",
      phoneNumber: "** ** ** ** **",
      description: "Description du partenaire par défaut",
      openTime: "Du **** au ***** de **h jusqu'a **h",
      email: "",
      website: "www.******.com",
    };
    let defaultSEOFR = {
      title: "titre SEO partenaire",
      description: "SEO description partenaire",
      ogimage: defaultImage,
    };

    let SEOfr = await API.post("/api/seos", {
      data: defaultSEOFR,
    });

    let adressFr = await API.post("/api/adresses", {
      data: defaultAdress,
    });

    await API.post("/api/partenaires", {
      data: {
        ...defaultPartenaire,
        adress: adressFr.data.data.id,
        banner: bannerfr.data.data.id,
        seo: SEOfr.data.data.id,
        photos_magasin: listFR.data.data.id,
      },
    });

    dispatch(handleInfo("Partenaire Ajouté", 1));
    dispatch(getAllPartenaires());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const deletePartenaire = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};

  dispatch({ type: "PARTENAIRE_LOADING" });
  try {
    await API.delete("/api/partenaires/" + id);
    dispatch(handleInfo("Partenaire Supprimé", 1));
    dispatch(getAllPartenaires());
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updatePartenaire =
  (id, partenaire, idAdress, adress) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    const query = qs.stringify({
      encodeValuesOnly: true,
    });
    console.log(partenaire);
    dispatch({ type: "PARTENAIRE_LOADING" });
    try {
      await API.put(`/api/adresses/${idAdress}?${query}`, {
        data: adress,
      });
      await API.put(`/api/partenaires/${id}`, { data: partenaire });

      dispatch(handleInfo("Partenaire Modifié", 1));
      dispatch(getPartenaireItem(id));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };
