const initialState = {
  articlesLoading: false,
  articles: null,
  articleItem: null,
  error: null,
};

export const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ARTICLES":
      return { ...state, articles: action.payload, articlesLoading: false };
    case "FETCH_ARTICLE_ITEM":
      return { ...state, articleItem: action.payload, articlesLoading: false };
    case "ARTICLE_LOADING":
      return { ...state, articlesLoading: true };
    default:
      return state;
  }
};
