import React from "react";
import {
  handleError,
  handleInfo,
  handleWelcome,
} from "../../../REDUX/actions/notifications";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "react-bootstrap";
import "./Notification.css";

const Notification = () => {
  const dispatch = useDispatch();

  const { welcomeMessage, welcome, info, infoMessage, error, errorMessage } =
    useSelector((state) => state.notificationsReducer);

  return (
    <>
      <ToastContainer className="myNotif">
        <Toast
          show={welcome}
          delay={4000}
          autohide
          onClose={() => dispatch(handleWelcome(null, 0))}
        >
          <Toast.Header className="bg-primary text-white">
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Welcome</strong>
          </Toast.Header>
          <Toast.Body className="f14 text-start bg-white py-4 text-primary">
            <span className="me-2">👋</span> {welcomeMessage}
          </Toast.Body>
        </Toast>
        <Toast
          show={info}
          delay={4000}
          autohide
          onClose={() => dispatch(handleInfo(null, 0))}
        >
          <Toast.Header className="bg-success text-white">
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Info</strong>
          </Toast.Header>
          <Toast.Body className="f14 text-start bg-white py-4 text-success d-flex align-items-center">
            <i className="bi bi-check-circle-fill me-4 f26 text-success"></i>
            {infoMessage}
          </Toast.Body>
        </Toast>
        <Toast
          show={error}
          delay={4000}
          autohide
          onClose={() => dispatch(handleError(null, 0))}
        >
          <Toast.Header className="bg-danger text-white">
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Erreur</strong>
          </Toast.Header>
          <Toast.Body className="f14 text-start bg-white py-4 text-danger d-flex align-items-center">
            <i className="bi bi-x-circle-fill f26 me-4"></i>
            {errorMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {/* 
      {welcome && (
        <div className={`alert alert-primary text-start  rounded-10`}>
          <div className="row d-flex align-items-center">
            <div className="col-11 d-flex align-items-center">👋 Bonjour Armand, bienvenue sur votre dashboard [“entreprise”] !</div>
            <div className="col">
              <i className="bi bi-x-lg text-end" onClick={() => dispatch(handleWelcome(null, 0))}></i>
            </div>
          </div>
        </div>
      )}
      {info && (
        <div className={`alert alert-success text-start  rounded-10`}>
          <div className="row d-flex align-items-center">
            <div className="col-11 d-flex align-items-center">
              
              {infoMessage}
            </div>
            <div className="col">
              <i className="bi bi-x-lg text-end" onClick={() => dispatch(handleInfo(null, 0))}></i>
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className={`alert alert-danger text-start  rounded-10`}>
          <div className="row d-flex align-items-center">
            <div className="col-11 d-flex align-items-center">
              
              {errorMessage}
            </div>
            <div className="col">
              <i className="bi bi-x-lg text-end" onClick={() => dispatch(handleError(null, 0))}></i>
            </div>
          </div>
        </div> 
      )}*/}
    </>
  );
};

export default Notification;
