import qs from "qs";

export const pageQueryAll = qs.stringify(
  {
    sort: ["name:asc"],
    populate: {
      sections: {
        populate: {
          image: {
            populate: "*",
          },
          image2: { populate: ["*"] },
          imageMobile: { populate: ["*"] },
          imageMobile2: { populate: ["*"] },
          list: {
            populate: {
              cards: {
                populate: ["image", "image2", "imageMobile", "imageMobile2"],
                sort: ["order:asc"],
              },
            },
          },
        },

        sort: ["order:asc"],
      },

      seo: {
        populate: "ogimage",
      },
    },
  },

  {
    encodeValuesOnly: true,
  }
);
