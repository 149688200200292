import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../REDUX/actions/confirmationModal";
import { deleteProduct } from "../../REDUX/actions/products";
import { bgImage } from "../../utils/utils";
import CustomButton from "../Common/CustomButton";
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteProduct(product.id));
  };

  const goToProduct = () =>
    navigate(`/products/${product.id}`, { state: { id: product.id } });

  const showModal = () =>
    dispatch(
      openModal({
        type: "delete",
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Êtes-vous sûr(e) de vouloir supprimer le produit ?",
        callBack: handleDelete,
      })
    );
  return (
    <div>
      <div
        className="productCardImage"
        style={{ backgroundImage: bgImage(product.attributes.image.data) }}
      ></div>

      <div className="text-start mt-4">{product.attributes.name}</div>
      <div className="text-start f14 mt-2">
        {product.attributes.places} places
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <CustomButton
            iconStart="trash-fill"
            type="red"
            callBack={showModal}
          />
        </div>
        <div className="col-6">
          <CustomButton iconStart="pencil-fill" callBack={goToProduct} />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
