import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ Title, Homme, Femme, NB }) => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    usePointStyle: true,
                    boxWidth: 5,
                    padding: 20,
                    font: {
                        align: 'end',
                        weight: 'bold',
                        size: '14',
                    },
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                            text: `${chart.data.labels[i]}                   ${data} (${percentage(data, totalGender)})`,
                            fillStyle: datasets[0].backgroundColor[i],
                        }))
                    }
                },
            },
            title: {
                display: true,
                position: 'top',
                align: 'start',
                text: Title,
                font: {
                    weight: 'bold',
                    size: '20',
                }
            },
        },
        cutout: '90%',
        radius: '90%'
    };

    const data = {
        labels: ['Homme', 'Femme', 'N/B      '],
        datasets: [
            {
                label: Title,
                data: [Homme, Femme, NB],
                backgroundColor: [
                    '#2CB67D',
                    '#7F5AF0',
                    '#130554',
                ],
                borderColor: [
                    '#2CB67D',
                    '#7F5AF0',
                    '#130554',
                ],
                borderWidth: 1,
                hoverOffset: 2,
                hoverBorderWidth: 10,
            },
        ],
    };

    /*
    NOTE: PLUGIN POUR FAIRE AFFICHER UN TEXTE AU CENTRE DU DOUGHNUT
     */
    /*
    const plugins = [{
        beforeDraw: function(chart) {
            const width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            const fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "rem sans-serif";
            ctx.textBaseline = "center";
            const text = totalGender,
                textX = Math.round((width - ctx.measureText(text).width) / 4),
                textY = height / 1.7;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]
     */

    function percentage(partialValue, totalValue) {
        return (partialValue*100 / totalValue).toFixed(0)+"%";
    }

    const totalGender = Math.round(Number(Homme) + Number(Femme) + Number(NB));

    return (
        <div className="card w-100 rounded-10">
            <div className="card-body m-3 mt-0 mb-3">
                <div className="align-items-center">
                    <div className="row align-items-center">
                        <div className="col-12 position-relative">
                            <select className="form-select position-absolute w-25" style={{right: 0, border: "none"}} aria-label="select month">
                                <option value="janvier" selected>Janvier</option>
                                <option value="février">Février</option>
                                <option value="mars">Mars</option>
                            </select>
                            <Doughnut options={options} data={data} /*plugins={plugins}*/ style={{maxHeight: 380, height: 380,}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoughnutChart;