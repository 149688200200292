import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../Common/CustomButton";
import EmptyList from "../Common/EmptyList";
import InputText from "../sectioninput/formelement/InputText";
import {
  addPartenaire,
  getAllPartenaires,
} from "../../REDUX/actions/partenaire";
import PartenaireCard from "./PartenaireCard";

const PartenaireList = () => {
  const dispatch = useDispatch();
  const [filter, setfilter] = useState("");

  const { partenairesData } = useSelector((state) => state.partenairesReducer);
  const handleAddPartenaire = () => {
    dispatch(addPartenaire());
  };
  const filterShowRoom = () => {
    dispatch(getAllPartenaires(filter));
  };

  return (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des partenaires</div>
      <div className="row align-items-center mt-2">
        <div className="col-9 d-flex align-items-center">
          <InputText
            flag={false}
            placeholder="rechercher un partenaire"
            handleChange={setfilter}
          />
          <div className="ms-3 col-1">
            <CustomButton
              iconStart={"search"}
              callBack={() => filterShowRoom()}
            />
          </div>
        </div>
        <div className="col-3">
          <CustomButton
            buttonText={"Ajouter un partenaire"}
            iconStart={"plus-lg"}
            callBack={handleAddPartenaire}
          />
        </div>
      </div>
      <div className="row mt-5 w-98 m-auto">
        <div className="col-4 f16 bolder text-start">Nom</div>
        <div className="col-5 f16 bolder text-start">Adresse</div>
        <div className="col-3 f16 bolder text-start">Actions</div>
      </div>
      <div className="mt-5">
        {partenairesData.length ? (
          partenairesData.map((partenaire, index) => (
            <PartenaireCard partenaire={partenaire} key={index} index={index} />
          ))
        ) : (
          <EmptyList text="Ajouter votre premier partenaire" />
        )}
      </div>
    </div>
  );
};

export default PartenaireList;
