import React, { useState } from "react";
import { useDispatch } from "react-redux";

import FileList from "../../Components/Files/FileList";
import { addImages } from "../../REDUX/actions/files";

const FilesView = () => {
  const dispatch = useDispatch();

  const [imagesUploaded, setimagesUploaded] = useState({});
  const addImage = (event) => {
    setimagesUploaded({
      images: event.target.files,
    });
  };
  const uploadFiles = (e) => {
    e.preventDefault();

    dispatch(addImages(imagesUploaded));
    setimagesUploaded({});
  };

  return (
    <div className="bg-white py-4 rounded-10">
      <form
        className="d-flex flex-column align-items-center mb-4"
        onSubmit={uploadFiles}
      >
        <input type="file" multiple name="files" onChange={addImage} />
        <button
          type="submit"
          className="btn btn-primary  text-white mt-3 w-70"
          disabled={!imagesUploaded.images}
        >
          Upload
        </button>
      </form>
      <FileList />
    </div>
  );
};

export default FilesView;
