import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import { changeUserRole, deleteUser } from "../../REDUX/actions/users";
import CustomButton from "../Common/CustomButton";

const UserCard = ({ user, index }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteUser(user.id));
  };

  const handleChangeRole = () => {
    dispatch(changeUserRole(!user.isAdmin, user.id));
  };

  const showModalDelete = () =>
    dispatch(openModal({ type: "delete", confirmButtonText: "Supprimer", cancelButton: true, message: "Êtes-vous sûr(e) de vouloir supprimer l'utilisateur ?", callBack: handleDelete }));

  const showModalRole = () =>
    dispatch(openModal({ confirmButtonText: "Changer", cancelButton: true, message: "Êtes-vous sûr(e) de vouloir changer le rôle de l'utilisateur?", callBack: handleChangeRole }));

  return (
    <div className={`w-98 mx-auto my-2 row py-4 align-items-center rounded-10 ${index % 2 === 0 ? "bg-lightGrey" : ""}`}>
      <div className="col-3 text-start">
        {user.firstName} {user.lastName}
      </div>
      <div className="col-3 text-start">{user.email}</div>
      <div className="col-2 text-start">{user.isAdmin ? "Administrateur" : "Rédacteur"}</div>
      <div className="col-4 text-start row justify-content-start">
        <div className="col-6">
          <CustomButton buttonText={user.isAdmin ? "Changer en Redacteur" : "Changer en Admin"} iconStart="arrow-left-right" callBack={showModalRole} />
        </div>
        <div className="col-6">
          <CustomButton buttonText={"Supprimer"} type="red" iconStart="trash3-fill" callBack={showModalDelete} />
        </div>
      </div>
    </div>
  );
};

export default UserCard;
