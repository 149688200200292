import API from "../../Api/Api.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllFiles =
  (dossierId, counter = 0, refetch = false) =>
  async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    const query = qs.stringify(
      {
        filters: dossierId
          ? {
              dossier: {
                id: {
                  $eq: dossierId,
                },
              },
            }
          : {
              dossier: {
                id: {
                  $null: true,
                },
              },
            },
        populate: {
          dossier: {
            populate: true,
          },
          media: { populate: "related" },
        },
        sort: ["createdAt:desc"],
        pagination: { start: counter, limit: 20 },
      },

      {
        encodeValuesOnly: true,
      }
    );
    if (!refetch) {
      dispatch({ type: "FILES_LOADING" });
    } else {
      dispatch({ type: "REFETCHING" });
    }
    try {
      let { data } = await API.get(`/api/fichiers?` + query);
      if (refetch) {
        dispatch({ type: "REFETCH_FILES", payload: data.data });
      } else {
        dispatch({ type: "FETCH_FILES", payload: data.data });
      }
    } catch (error) {
      dispatch(handleError("Erreur reseau", 1));

      console.log(error);
    }
  };

export const getDossiers = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      populate: "*",
      sort: ["createdAt:desc"],
    },

    {
      encodeValuesOnly: true,
    }
  );

  try {
    let { data } = await API.get(`/api/dossiers?${query}`);
    dispatch({ type: "FETCH_DOCUMENTS", payload: data.data });
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }
};

export const addImages = (imagesUploaded) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  dispatch({ type: "FILES_LOADING" });
  try {
    let imageForm = new FormData();
    Array.from(imagesUploaded.images).forEach((image) =>
      imageForm.append("files", image)
    );

    await API.post(`/api/adress/webpConverterUploader`, imageForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch(getAllFiles());
    dispatch(handleInfo("Image(s) ajoutée(s)", 1));
    imageForm.delete("images");
  } catch (error) {
    dispatch(handleError("Erreur lors de l'ajout de l'image", 1));

    console.log(error);
  }
};

export const newFolder = (name) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.post(`/api/dossiers`, {
      data: { name: name.length ? name : "nouveau dossier" },
    });
    dispatch(getDossiers());
    dispatch(handleInfo("Dossier créé", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la création du dossier", 1));
    console.log(error);
  }
};

export const renameFolder = (id, name) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    await API.put(`/api/dossiers/${id}`, { data: { name: name } });
    dispatch(getDossiers());
    dispatch(handleInfo("Dossier renommé", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors du changement du nom", 1));
    console.log(error);
  }
};

export const renamefile = (id, name) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  try {
    const { data } = await API.put(`/api/fichiers/${id}`, {
      data: { name: name },
    });
    dispatch({
      type: "RENAME_FILE",
      payload: { id: data.data.id, name: data.data.attributes.name },
    });
    dispatch(handleInfo("Fichier renommé", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors du changement du nom", 1));
    console.log(error);
  }
};

export const deleteImage = (id, fichier) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "FILES_LOADING" });
  try {
    await API.delete(`/api/upload/files/${id} `);
    await API.delete(`/api/fichiers/${fichier} `);
    dispatch(getAllFiles());
    dispatch(closeModal());
    dispatch(handleInfo("Image supprimée", 1));
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression de l'image", 1));

    console.log(error);
  }
};

export const moveFiles =
  (destination, fichiers, allDossiers, openDossierId) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    if (destination !== "/") {
      const oldFiles = allDossiers.filter(
        (dossier) => dossier.id === parseInt(destination)
      )[0].fichiersId;

      dispatch({ type: "FILES_LOADING" });
      try {
        await API.put(`/api/dossiers/${destination}`, {
          data: { fichiers: [...oldFiles, ...fichiers] },
        });

        dispatch(getAllFiles(openDossierId));
        dispatch(getDossiers());
        dispatch(handleInfo("Fichiers déplacés", 1));
      } catch (error) {
        dispatch(handleError("Erreur lors du déplacement des fichiers", 1));
        console.log(error);
      }
    } else {
      try {
        for (let i = 0; i < fichiers.length; i++) {
          const id = fichiers[i];
          await API.put(`/api/fichiers/${id}`, { data: { dossier: null } });
        }
        dispatch(getAllFiles(openDossierId));
        dispatch(getDossiers());
      } catch (error) {
        dispatch(handleError("Erreur lors du déplacement des fichiers", 1));
        console.log(error);
      }
    }
  };
